import './App.css';
import RouterPage from './pages/RouterPage';

function App() {
  return (
    <div>
      <RouterPage />
    </div>
  );
}

export default App;
