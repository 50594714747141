import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
  let { user } = useContext(AuthContext);
  return user ? <Outlet /> : <Navigate to="/" />;
  // return <Route {...rest}>{!user ? <Navigate replace to="/" /> : children}</Route>;
};

export default PrivateRoute;